import React from "react"
import SectionTitle from "./section-title"
import Divider from "./divider"

import styles from "./locations.module.css"
import { useStaticQuery, graphql } from "gatsby"
import LocationCard from "./location-card"

export default function Locations({ data }) {
  return (
    <section className={`${styles.wrapper} constrained`}>
      <Divider></Divider>
      <header data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
        <SectionTitle title="locations" center></SectionTitle>
      </header>
      <div className={styles.grid}>
        {data.edges.map(({ node }, index) => (
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay={100 * index + 500}
            className={styles.col}
            key={node.id}
          >
            <LocationCard data={node}></LocationCard>
          </div>
        ))}
      </div>
    </section>
  )
}
