import React from "react"
import WhatWeDoSlider from "./what-we-do-slider"
import SectionTitle from "./section-title"
import Divider from "./divider"

import styles from "./what-we-do.module.css"

export default function WhatWeDo({ data }) {
  return (
    <section className={styles.wrapper} id="whatwedo">
      <Divider white></Divider>
      <header
        className="whatwedo-header"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <SectionTitle
          white
          center
          subTitle="whatWeDoSubTitle"
          title="whatWeDoTitle"
        ></SectionTitle>
      </header>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250">
        <WhatWeDoSlider data={data}></WhatWeDoSlider>
      </div>
    </section>
  )
}
