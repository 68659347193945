import React from "react"

import SectionTitle from "./section-title"

import styles from "../components/photo-gallery.module.css"
import Divider from "./divider"

import { StaticImage } from 'gatsby-plugin-image'

const  img1 = "../images/img1.jpg"
const  img2 = "../images/img2.jpg"
const  img3 = "../images/img3.jpg"
const  img5 = "../images/img5-2.jpg"
const  img4 = "../images/img4.jpg"
const  imgA = "../images/img-a.jpg"
const  imgB = "../images/img-b.jpg"

export default function PhotoGallery() {
  return (
    <section className={styles.wrapper}>
      <Divider></Divider>
      <div
        className="photo-gallery-header"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="150"
      >
        <div className="constrained">
          <SectionTitle title="photoGallery" center></SectionTitle>
        </div>
      </div>
      <div
        className="photo-gallery-container"
        data-aos="fade-in"
        data-aos-duration="2000"
        data-aos-delay="150"
      >
        <div className="wrapper">
          <div
            className="box one"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={img1} />
          </div>
          <div
            className="box two"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={img2} />
          </div>
          <div
            className="box three"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={img3} />
          </div>
          <div
            className="box four"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={img5} />
          </div>
          <div
            className="box five"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={img4} />
          </div>
          <div
            className="box six"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={imgA} />
          </div>
          <div
            className="box seven"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="150"
          >
            <StaticImage className="grid-image" src={imgB} />
          </div>
        </div>
      </div>
    </section>
  )
}
