import React, { useState } from "react"

import { flow, groupBy, map } from "lodash/fp"

import styles from "./what-we-do-slider.module.css"

export default function WhatWeDoSlider({ data: { edges: data } }) {
  const categories = flow(
    groupBy("node.frontmatter.category"),
    map(items => {
      return { title: items[0].node.frontmatter.category, items: items }
    })
  )(data)

  const [activeCategory, setActiveCategory] = useState(categories[0].title)

  return (
    <div className={`constrained ${styles.wrapper}`}>
      <div className={styles.sidebar}>
        {categories.map(category => (
          <button
            className={styles.categoryButton}
            onClick={() => {
              setActiveCategory(category.title)
            }}
            key={`filter ${category.title}`}
            active={category.title === activeCategory ? "true" : null}
          >
            {category.title}
          </button>
        ))}
      </div>

      <section className={styles.content}>
        {categories.map(category => (
          <div
            style={{
              display: category.title === activeCategory ? "block" : "none",
            }}
            key={`category ${category.title}`}
          >
            <h2 style={{ display: "none" }}>{category.title}</h2>
            <div className={styles.grid}>
              {category.items.map(({ node }) => {
                const { title, fullWidth } = node.frontmatter
                return (
                  <article
                    fullwidth={fullWidth ? "true" : null}
                    // fullWidth={"true"}
                    id={node.id}
                    key={node.id}
                  >
                    {title && <h3 className={styles.serviceTitle}>{title}</h3>}
                    <div
                      className={styles.text}
                      dangerouslySetInnerHTML={{ __html: node.html }}
                    ></div>
                  </article>
                )
              })}
            </div>
          </div>
        ))}
      </section>
    </div>
  )
}
