import React from "react"

import caribelLogo from "../images/caribel-logo.svg"
import { FormattedMessage } from "react-intl"
import LanguageSwitch from "./language-switch"
import { StaticImage } from "gatsby-plugin-image"

const imageHeader = "../images/image-header3.jpg"

export default function Header() {
  return (
    <section className="header-section">
      <header className="logo-language">
        <img className="logo" src={caribelLogo} />

        <LanguageSwitch></LanguageSwitch>
      </header>

      <div className="header">
        <div className="intro-header">
          <h1>
            <FormattedMessage id="title">
              Caribel maakt uw wagen weer als nieuw
            </FormattedMessage>
          </h1>
          <p>
            <FormattedMessage id="description"></FormattedMessage>
          </p>
        </div>
        <div className="image-header-container-50">
          <div className="image-header-container">
            <div className="image-header-mask fadeImageOut ">
              <StaticImage className="image-header" src={imageHeader} />
            </div>

            <div className="more-info-button">
              <a
                href="#about"
                onClick={e => {
                  e.preventDefault()
                  const href = e.currentTarget.getAttribute("href")
                  document.querySelector(href).scrollIntoView({
                    behavior: "smooth",
                  })
                }}
              >
                <FormattedMessage id="moreInfo"></FormattedMessage>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
