import React from "react"
import { Link } from "gatsby"

import styles from "./language-switch.module.css"

export default function LanguageSwitch() {
  return (
    <nav class="language">
      <div class="language-switch">
        <Link
          activeClassName={styles.isActive}
          className={styles.link}
          to="/nl"
          partiallyActive={true}
        >
          nl
        </Link>
        <Link
          activeClassName={styles.isActive}
          className={styles.link}
          to="/fr"
          partiallyActive={true}
        >
          fr
        </Link>
        <Link
          activeClassName={styles.isActive}
          className={styles.link}
          to="/en"
          partiallyActive={true}
        >
          en
        </Link>
      </div>
    </nav>
  )
}
