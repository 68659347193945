import React from "react"

import styles from "./about.module.css"
import SectionTitle from "./section-title"
import Divider from "./divider"

export default function About({ data }) {
  return (
    <div className="constrained" id="about">
      <div className={styles.wrapper}>
        <Divider></Divider>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="150"
          className={styles.header}
        >
          <SectionTitle title={data.frontmatter.title}></SectionTitle>
        </div>
        <div className={styles.row}>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="250"
            className={styles.col}
          >
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: data.html }}
            ></div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="350"
            className={styles.col}
          >
            <div className={styles.text}>
              <h3 className={styles.checkmarkTitle}>
                {data.frontmatter.qualityTitle}
              </h3>
              <ul>
                {data.frontmatter.qualityBullets.map(bullet => (
                  <li key={bullet}>{bullet}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
