import React from "react"
import Divider from "./divider"
import SectionTitle from "./section-title"

import styles from "./contact-form.module.css"
import { FormattedMessage } from "react-intl"

export default function ContactForm() {
  return (
    <>
      <section className="contactform-container" id="contactform">
        <Divider></Divider>

        <header
          className="contact-header"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          <SectionTitle title={"contactUs"} center></SectionTitle>
        </header>

        <div
          className="form-container"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="250"
        >
          <div className="intro-contact">
            <h1>
              <FormattedMessage id="formTitle"></FormattedMessage>
            </h1>
          </div>

          <div className="form-message"></div>

          <div className="form">
            <form
              className="contact-form"
              action="https://formspree.io/sales@caribel.be"
              method="POST"
            >
              <div className={styles.formInner}>
                <div className="input">
                  <label htmlFor="name">
                    <FormattedMessage id="formName"></FormattedMessage>:
                  </label>
                  <input
                    className="inputfocus"
                    type="text"
                    name="lastname"
                    placeholder="Bel"
                    required
                  />
                </div>

                <div className="input">
                  <label htmlFor="lastname">
                    <FormattedMessage id="formFirstName"></FormattedMessage>:
                  </label>
                  <input
                    className="inputfocus"
                    type="text"
                    name="name"
                    placeholder="Cari"
                    required
                  />
                </div>

                <div className="input">
                  <label htmlFor="mail">
                    <FormattedMessage id="formEMailAddress"></FormattedMessage>:
                  </label>
                  <input
                    className="inputfocus"
                    type="email"
                    name="_replyto"
                    placeholder="cari-b@hotmail.com"
                    required
                  />
                </div>

                <div className="input">
                  <label htmlFor="location">
                    <FormattedMessage id="formLocation"></FormattedMessage>:
                  </label>
                  <div>
                    <select name="locations" required>
                      <optgroup label="Kies een locatie">
                        <option name="location" value="Lier">
                          Lier
                        </option>
                        <option name="location" value="Hasselt">
                          Hasselt
                        </option>
                        <option name="location" value="Vilvoorde">
                          Vilvoorde
                        </option>
                      </optgroup>
                    </select>
                    {/* redirect <input type="hidden" name="_next" value="https://caribel.uruku.be/#contactform"/> */}
                    {/* voor in cc enz.
                            <input className="is-hidden" type="text" name="_gotcha">
                            <input type="hidden" name="_subject" value="Subject">
                            <input type="hidden" name="_cc" value="fanny@uruku.be">  */}
                  </div>
                </div>

                <div className={`input ${styles.fullWidth}`}>
                  <label htmlFor="msg">
                    <FormattedMessage id="formYourQuestion"></FormattedMessage>:
                  </label>
                  <textarea
                    id="msg"
                    name="user_message"
                    placeholder="Lorem ipsum dolor sit amet…"
                    required
                    rows={3}
                  ></textarea>
                </div>
                <div className={`input ${styles.fullWidth}`}>
                  <input type="checkbox" required id="gdpr" name="user_gdpr" />
                  <label htmlFor="gdpr">
                    <FormattedMessage id="formGDPR"></FormattedMessage>*
                  </label>
                </div>
              </div>

              <div className="container-button-contactform">
                <button
                  className="button-contactform"
                  type="submit"
                  value="verzend"
                >
                  <FormattedMessage id="formSend"></FormattedMessage>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
