import React from "react"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import SectionTitle from "./section-title"

import styles from "./partners.module.css"
import Divider from "./divider"

export default function Partners() {
  const {
    allPartnersYaml: { nodes: partners },
  } = useStaticQuery(graphql`
    {
      allPartnersYaml {
        nodes {
          id
          logo {
            publicURL
          }
          website
        }
      }
    }
  `)

  return (
    <section className={styles.wrapper}>
      <Divider></Divider>
      <header
        className="contact-header"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="250"
      >
        <SectionTitle title={"Partners"} center></SectionTitle>
      </header>
      <div className={styles.grid}>
        {partners.map(partner => {
          return (
            <div key={partner.id} className={styles.partner}>
              <a rel="noreferrer" href={partner.website} target="_blank">
                <img src={partner.logo.publicURL} alt="" />
              </a>
            </div>
          )
        })}
      </div>
    </section>
  )
}
