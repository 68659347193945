import React from "react"

import styles from "./location-card.module.css"

import marker from "../images/marker.svg"

import address from "../images/address.svg"
import phone from "../images/phone.svg"
import mail from "../images/mail.svg"
import { FormattedMessage } from "react-intl"

export default function LocationCard({ data }) {
  return (
    <article className={styles.card}>
      <h2 className={styles.title}>
        <img src={marker} alt="" />
        {data.frontmatter.title}
      </h2>
      <div className={styles.content}>
        <div dangerouslySetInnerHTML={{ __html: data.html }}></div>
        <div className={styles.footer}>
          <h3>
            <FormattedMessage id="whereCanYouFindUs"></FormattedMessage>
          </h3>

          <div className={styles.info}>
            <img src={address} alt="" />
            {data.frontmatter.address}
          </div>
          <div className={styles.info}>
            <img src={phone} alt="" />
            {data.frontmatter.phone}
          </div>
          <div className={styles.info}>
            <img src={mail} alt="" className={styles.mail} />
            <a href={`"mailto:${data.frontmatter.email}"`}>
              {data.frontmatter.email}
            </a>
          </div>
          <div className={styles.actions}>
            <a
              href={data.frontmatter.mapLink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.button}
            >
              <FormattedMessage id="planYourRoute"></FormattedMessage>
            </a>
          </div>
        </div>
      </div>
    </article>
  )
}
