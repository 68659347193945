import React from "react"

import styles from "./section-title.module.css"
import { FormattedMessage } from "react-intl"

export default function SectionTitle({ title, subTitle, white, center }) {
  return (
    <>
      <div className={`${styles.wrapper} ${white && styles.white}`}>
        <h2 className={`${styles.title} ${center && styles.center}`}>
          <FormattedMessage id={title}></FormattedMessage>
        </h2>
        {subTitle && (
          <div className={styles.subTitle}>
            <FormattedMessage id={subTitle}></FormattedMessage>
          </div>
        )}
      </div>
    </>
  )
}
