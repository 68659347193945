import React from "react"
import { injectIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import About from "../components/about"
import WhatWeDo from "../components/what-we-do"
import Locations from "../components/locations"
import PhotoGallery from "../components/photo-gallery"
import Partners from "../components/partners"
import ContactForm from "../components/contact-form"
import { graphql } from "gatsby"

const IndexPage = (props) => {
  console.log("props:", props)
  const {
    data: { about, whatWeDoItems, locations },
  } = props
  return (
    <Layout>
      <SEO title="Home" />
      <Header></Header>
      <About data={about}></About>
      <WhatWeDo data={whatWeDoItems}></WhatWeDo>
      <Locations data={locations}></Locations>
      <PhotoGallery></PhotoGallery>
      <Partners></Partners>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default injectIntl(IndexPage)

export const query = graphql`
  query pageQuery($localeRegex: String) {
    about: markdownRemark(
      frontmatter: { id: { eq: "about" } }
      fileAbsolutePath: { regex: $localeRegex }
    ) {
      frontmatter {
        title
        qualityTitle
        qualityBullets
      }
      html
    }

    whatWeDoItems: allMarkdownRemark(
      sort: { fields: frontmatter___weight }
      filter: {
        frontmatter: { type: { eq: "whatwedo" } }
        fileAbsolutePath: { regex: $localeRegex }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            category
            fullWidth
          }
        }
      }
    }

    locations: allMarkdownRemark(
      sort: { fields: frontmatter___weight }
      filter: {
        frontmatter: { type: { eq: "location" } }
        fileAbsolutePath: { regex: $localeRegex }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            type
            title
            address
            email
            phone
            mapLink
          }
          html
        }
      }
    }
  }
`
